<template>
  <base-section
    id="ourMission"
    :class="isMobile ? 'mt-3 mb-6 mx-3 py-0 rounded-lg' : 'ma-10 py-5 rounded-xl'"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
        >
          <base-section-heading
            align="left"
            :subtitle="$t('zcp.subtitle')"
            mobile-size="text-h5"
            tag="h2"
            :title="$t('zcp.title2')"
            :space="isMobile ? 4 : 12"
          />
          <v-row>
            <base-body
              :class="isMobile ? 'px-3 mt-2' : 'px-12 mt-5'"
              space="5"
            >
              {{ $t('zcp.content4') }}
            </base-body>
            <base-body
              :class="isMobile ? 'px-3' : 'px-12'"
              space="5"
            >
              {{ $t('zcp.content5') }}
            </base-body>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'OurMission',

    data: () => ({
    }),
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.width <= 768
      },
    },
  }
</script>
